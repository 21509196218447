const CryptoJS = require('crypto-js')


export const EncryptAPI = string => {
  let key = process.env.REACT_APP_API_KEY
  let encrypted = CryptoJS.AES.encrypt(string, key)
  return String(encrypted)
}

export const DecryptAPI = crypt => {
  let key = process.env.REACT_APP_API_KEY
  let decrypted = CryptoJS.AES.decrypt(crypt, key)
  return decrypted.toString(CryptoJS.enc.Utf8)
}


export const EncryptArray = data => {
    let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_KEY).toString()
    return ciphertext
}

export const DecryptArray = cipherdata => {
    let bytes         = CryptoJS.AES.decrypt(cipherdata, process.env.REACT_APP_KEY)
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
}

export const Encrypt = string => {

  let encrypted = CryptoJS.AES.encrypt(string, process.env.REACT_APP_KEY)
  let encode    = encode64(encrypted.toString())

  return encode

}

export const Decrypt = crypt => {

  let decode    = decode64(crypt)
  let decrypted = CryptoJS.AES.decrypt(decode, process.env.REACT_APP_KEY)

  return decrypted.toString(CryptoJS.enc.Utf8)

}

function encode64(myString) {

  const encodedWord = CryptoJS.enc.Utf8.parse(myString)
  const encoded    = CryptoJS.enc.Base64.stringify(encodedWord)

  return encoded
}

function decode64(encoded) {

  const encodedWord = CryptoJS.enc.Base64.parse(encoded)
  const decoded     = CryptoJS.enc.Utf8.stringify(encodedWord)

  return decoded

}
