import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';

import App from './App/index';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import config from '@core/config';
import "@src/assets/css/styles.css";
import "@src/assets/css/custom.css";
import history from './services/Helpers/history';

const store = createStore(reducer);

const app = (
    <Provider store={store}>
        <Router basename={config.basename} history={history}>
            {/* basename="/datta-able" */}
            <App />
        </Router>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
