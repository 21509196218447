import { version } from './../../package.json';
import { Encrypt, Decrypt } from '@services/Helpers/encrypt';
import history from '@services/Helpers/history';


/* Configuração das variáveis do localStorage */
export const MV_STORE = '_@movingpay'


/* Configurações de autenticações */
export const isAuthenticated = () => {

	const situacao = Recuperar('token_acesso')

	return !situacao ? false : true

};


/* Configurações de TOKEN */
export const getToken = () => Recuperar('token_acesso');
export const getTokenValid = () => Recuperar('validade_token');
export const setTokenValid = (contador) => Atualizar('validade_token', contador);

/* Configuração de permissões de acesso */
export const getMovingPayPermissoes = () => Recuperar('permissoes');
export const setMovingPayPermissoes = (permissoes) => Atualizar('permissoes', permissoes);

/* Configurações de versões */
export const setRemoteVersion = (versao) => Atualizar('versao_remota', versao.toString().trim());
export const getRemoteVersion = () => Recuperar('versao_remota');
export const getLocalVersion = () => version.toString().trim();

/* Recupera o nome do usuário */
export const getUserName = () => Recuperar('usuario_nome');
export const getLayout = () => Recuperar('layout_unico');

/* Recupera a logo da empresa */
export const getEmpresaId = () => Recuperar('empresa_id')
export const getLogo = () => Recuperar('empresa_logo');
export const getMerchantObject = () => Recuperar('estabelecimento');
export const getEstabelecimento = () => {

	const estabelecimento = Recuperar('estabelecimento');

	if (!estabelecimento) return [];

	return [{
		key: estabelecimento.merchant_id,
		text: `[${estabelecimento.document_number}] ${estabelecimento?.name?.toUpperCase()}`,
		value: estabelecimento.merchant_id,
	}]

};

/* Configurações de Login */
export const login = (response) => {

	/* Prepara o objeto de encriptação */
	let o 			 = Object.create(null);
	o.token_acesso   = response.access_token;
	o.versao_remota  = null;
	o.versao_local   = version.toString().trim();
	o.alerta 		 = 0;
	o.usuario_nome   = response.user.nome;
	o.validade_token = response.expires_at;
	o.empresa_logo   = response.logo_url ? "https://movingpay-logos.s3.sa-east-1.amazonaws.com/" + response.logo_url : "";
	o.permissoes 	 = [];
	o.perfil 		 = response.user.perfil_id;
	o.layout_unico   = response.layout_type;
	o.empresa_id = response.user.customers_id;

	/* Salva o objeto no Storage */
	localStorage.setItem(MV_STORE, Encrypt(JSON.stringify(o)));

};

/* Configurações de logout */
export const logout = () => {

	/* Remove a permissão */
	localStorage.removeItem(MV_STORE);

	/* Desconecta do Socket */
	// if (Socket) Socket.disconnect();

	/* Redireciona o usuário */
	history.push('/login', { mensagem: "Seu acesso expirou. Efetue o login para continuar." })
};

/* Recupera informações do localStorage */
function Recuperar(chave) {

	/* Decripta o objeto */
	let objeto = localStorage.getItem(MV_STORE) ? JSON.parse(Decrypt(localStorage.getItem(MV_STORE))) : null

	if (objeto) {
		return objeto[chave]
	} else {
		return null
	}

}

/* Atualizar as informações do localStorage */
function Atualizar(chave, valor) {

	/* Verifica se o objeto existe */
	let objeto = localStorage.getItem(MV_STORE)
	if (!objeto) return 0

	/* Decripta o objeto */
	let objeto_decript = JSON.parse(Decrypt(objeto))

	/* Atualiza a chave solicitada */
	objeto_decript[chave] = valor

	/* Salva novamente o objeto encriptado */
	localStorage.setItem(MV_STORE, Encrypt(JSON.stringify(objeto_decript)))

	return 0

}


