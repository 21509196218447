import React from 'react';

const Login 										= React.lazy(() => import('@pages/Authentication/Login'));
const RecuperarSenha     				= React.lazy(() => import('@pages/Authentication/RecuperarSenha'));
const RecuperarSenhaConfirmar 	= React.lazy(() => import('@src/App/pages/Authentication/RecuperarSenhaConfirmar'));

const route = [
    { path: '/login', exact: true, private: false, name: 'Login', component: Login },
    { path: '/recuperar-senha', exact: true, private: false, name: 'Recuperar Senha', component: RecuperarSenha },
    { path: '/recuperar-senha/confirmar', exact: true, private: false, name: 'Recuperar Senha', component: RecuperarSenhaConfirmar }
];

export default route;
